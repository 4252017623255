<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
      @click="onClickRow($event)"
    />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/infoViewDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      model: this.$store.getters["config/get"].models.info.list,
      url: "/kazan/information",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Информация";
  },

  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
